/*==================
    Portfolio Area 
==================*/
.careers {
    margin-top: 30px;
    overflow: hidden;
    border-radius: 6px;
    position: relative;
    .thumbnail {
        display: block;
        position: relative;
        background-color: #0a0a0a;
        transform: scale(1.13) translateZ(0);
        backface-visibility: hidden;
        transition: transform .28s ease;
        z-index: 1;
        img {
            width: 100%;
            @extend %transition;
        }
        &::before{
            z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            background:  #0a0a0a;
            opacity: .5;
            @extend %transition;
        }

        &::after {
            background: linear-gradient(to bottom, #3e20c4 0, #000000 100%);
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 1;
            opacity: 0;
            @extend %transition;

        }
    }
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 35px 40px;
        z-index: 2;
        h4 {
            &.title {
                color: #ffffff;
                margin: 0;
                opacity: 0;
                visibility: hidden;
                transition-delay: 0.25s;
                transform: translateY(10px);
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);

                a {
                    color: #ffffff;
                }
            }
        }
        p {
            &.designation {
                color: #c6c9d8;
                font-size: 14px;
                line-height: 34px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
                transform: translateY(10px);
            }
        }
    }

    .body_content {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        max-width: 100%!important;
        z-index: 10;
        padding: 0;
        @media #{$lg-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$md-layout} {
            left: 20px;
            right: 20px;
        }
        @media #{$sm-layout} {
            left: 20px;
            right: 20px;
        }
        .inner {
            p {
                color: #c6c9d8;
                font-size: 14px;
            }
            h4 {
                font-size: 24px;
                line-height: 36px;
                a{
                    color: #ffffff;
                }
            }
            .title {
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
            }
            .portfolio-button{
                @extend %transition;
                margin-top: 35px;
                transition: 0.7s;
                @media #{$large-mobile} {
                    margin-top: 25px;
                }
                a {
                    &.rn-btn{
                        color: #ffffff;
                        border-color: rgba(255,255,255,0.3);
                        &:hover{
                            border-color: $theme-color;
                        }
                    }
                }
            }
        }
    }

    ul {
        &.social-icon {
            position: absolute;
            top: 25px;
            left: 35px;
            height: 1000px;
            padding: 0;
            z-index: 2;
            list-style: none;
            display: flex;
            margin: 0 -10px;
            li {
                margin: 0 10px;
                transform: translateY(8px) scale(0.8);
                opacity: 0;
                visibility: hidden;
                transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);

                a {
                    color: #ffffff;
                    font-size: 15px;
                    transition: 0.3s;
                    svg{
                        transition: 0.3s;
                    }
                    &:hover{
                        svg{
                            transform: scale(1.3);
                        }

                    }
                }
                
            }
        }
    }
    &.team-style--bottom {
        ul {
            &.social-icon {
                top: auto;
                left: 40px;
                bottom: 105px;
            }
        }
        .content  {
            h4 {
                &.title {
                    margin-bottom: 1px;
                }
            }
        }
    }


    &:hover {
        .content {
            h4 {
                &.title {
                    transition-delay: 0.25s;
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }

            p{
                &.designation {
                    transition-delay: 0.33s;
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                }
            }
        }
        .thumbnail {
            &::before{
                opacity: 0.0;
            }
            &::after{
                opacity: 0.85;
                top: 0;
            }
        }
        ul {
            &.social-icon {
                li {
                    opacity: 1;
                    width: 300;
                    visibility: visible;
                    transform: translateY(0);
                    
                    &:nth-child(1){
                        -webkit-transition-delay: .07692s;
                        transition-delay: .07692s;
                    }

                    &:nth-child(2){
                        -webkit-transition-delay: .15385s;
                        transition-delay: .15385s;
                    }

                    &:nth-child(3){
                        -webkit-transition-delay: .23077s;
                        transition-delay: .23077s;
                    }

                    &:nth-child(4){
                        -webkit-transition-delay: .33077s;
                        transition-delay: .23077s;
                    }

                    &:nth-child(5){
                        -webkit-transition-delay: .43077s;
                        transition-delay: .23077s;
                    }
                }
            }
        }
        .content {
            opacity: 1;
            visibility: visible;
        }
        .body_content {
            opacity: 0;
            visibility: hidden;
        }
    }
}
