/*====================
    About Area 
======================*/


.about-inner {
    @media #{$md-layout} {
        padding-top: 40px;
    }
    @media #{$sm-layout} {
        padding-top: 40px;
    }
    .section-title{
        h2{
            &.title{
                margin-bottom: 8px;
            }
        }
        h3{
            margin-bottom: 8px;
            line-height: 32pt;
            font-size: xx-large;
        }
        p{
            &.description{
                font-weight: 300;
                color: #717173;
                a{
                    color: rgba(29, 29, 36, 1);
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }

    }
}

.about-title {
    background-position: 50% 40%;

    @media #{$sm-layout} {
        background-position: 50% 50%;
    }
}

.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;
        img{
            border-radius: 8px;
           
        }
    }
}

.about-position-top {
    @media #{$lg-layout} {
        padding-top: 120px;
    }
    @media #{$md-layout} {
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-top: 60px;
    }
    .thumbnail {
        margin-top: -130px;
        @media #{$lg-layout} {
            margin-top: 0;
        }
        @media #{$laptop-device} {
            margin-top: -89px;
        }
        @media #{$md-layout} {
            margin-top: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
        }
    }
}
