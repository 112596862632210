.jobs {

    @extend %transition;
    min-height: 600px;
    width: 100%;
    padding-top: 2%;
    
    
    @media #{$laptop-device} {
        min-height: 500px;
    }
    @media #{$lg-layout} {
        min-height: 500px;
    }
    @media #{$md-layout} {
        min-height: 450px;
    }
    @media #{$sm-layout} {
        min-height: 400px;
    }
    h2{
        &.title{
            padding-bottom: 20%;
            font-size: 60px;

            
            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 36px;
                margin-bottom: 7px;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
        a {
            color: rgba(29, 29, 36, 1);
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;    
            }
        }
    }



    &.text-center {
        .content {
            position: absolute;
            bottom: 40px;
            left: 40px;
            max-width: 80% !important;
            z-index: 10;
            padding: 0;
            right: 40px;
            margin: 0 auto;
            @media #{$lg-layout} {
                left: 20px;
                right: 20px;
            }
            @media #{$md-layout} {
                left: 15px;
                right: 15px;
            }
            @media #{$sm-layout} {
                left: 10px;
                right: 10px;
            }
        }
    }
    &:hover{
        .thumbnail-inner{
            transform: scale(1.08) translateZ(0);
            &::before{
                opacity: 0.85;
            }
        }
        .thumbnail {
            transform: scale(1) translateZ(0);
            &::after{
                background-color: rgba(25,25,25,.24);
            }
        }
        .bg-blr-image{
            opacity: 0;
            z-index: 9;
        }
        
    }
}


input[type="file"] {
    display: none;
}
.custom-file-upload {
    button {
        display: none;
    }
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #000000;
    padding: 6px 12px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;
    @media #{$sm-layout} {
        padding: 9px 15px;
        font-size: 12px;
    }
    cursor: pointer;
    &:hover{
        background: transparent;
        color: $theme-color;
        box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
    }
}

.job-grid-container {
    display: grid;
    grid-template-columns: 150px 220px;
    grid-gap: 5px;
}

.job-grid-item-1{
    grid-column: 1 / -1;
}

.job-grid-item-2{
    grid-row-start: 2;
    grid-row-end: 4
}

.job-grid-item-3{
    grid-row-start: 2;
    grid-row-end: 4
}

.submit-button {
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #000000;
    padding: 10px 18px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;
    @media #{$sm-layout} {
        padding: 9px 25px;
    }
    
    &:hover{
        background: transparent;
        color: $theme-color;
        box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
        transform: translateY(-5px);
    }
}

