.photo_carousel {
    p{
        text-align: left;
    }
    .slick-dots {   
        bottom: -60px;
        @media #{$large-mobile} {
            bottom: -50px; 
        }
        li{
            width: 30px;
            margin: 0;
            opacity: .35;
            position: relative;
            
            button{
                &::before{
                    display: none;
                }
                &::after{
                    background: #333;
                    width: 100%;
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    @extend %transition;
                    border-radius: 50%;
                    margin-left: 11px;
                    background-color: transparent;
                    box-shadow: inset 0 0 0 5px #000;
                }
            }
            &.slick-active{
                opacity: 1;
                button{
                    &::after{
                        -webkit-transform: scale(2);
                        transform: scale(2);
                        box-shadow: inset 0 0 0 1px $theme-color;
                        background-color: transparent;
                        background-color: transparent!important;
                    }
                }
            }
        }
    }
    // Dot Light  
    &.dot-light {
        .slick-dots {
            li{
                button {
                    &::after {
                        background: #ffffff;
                        box-shadow: inset 0 0 0 5px #ffffff;
                    }
                }
                &.slick-active{
                    opacity: 1;
                    button{
                        &::after{
                            -webkit-transform: scale(2);
                            transform: scale(2);
                            box-shadow: inset 0 0 0 1px $theme-color;
                            background-color: transparent;
                            background-color: transparent!important;
                        }
                    }
                }
            }
        }
    }
}

.slick-space-gutter--15 {
    margin-left: -15px;
    margin-right: -15px;
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }
    .portfolio {
        margin: 40px 0;
    }
}